// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"

import './src/jquery'

import Filter from './src/filter'
import Modal from './src/modal'

document.addEventListener('turbo:load', () => {
  new Filter();
  new Modal();
  $('.hamburger').on('click', function(e) {
    e.stopPropagation();
    $(this).toggleClass('hamburger__change');
    if ($(this).hasClass('hamburger__change'))
      $('.mega-menu').slideDown();
    else
      $('.mega-menu').slideUp();
  });
});
