import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'country', 'state' ]

  connect() {
    this.configureStates()
  }

  configureStates(event) {
    this.stateTargets.forEach(element => {
      element.required = this.countryTarget.value == element.dataset.country
      element.hidden = element.disabled = !element.required
    })
  }
}
