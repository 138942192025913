import $ from 'jquery'

class Filter {
  constructor() {
    let $highightsFilter = $('._filter');
    if ($highightsFilter.length > 0) {
      $highightsFilter.find('a').on('click', function(e) {
        e.stopPropagation();
        let $this = $(this),
            selector = $this.data('filter-selector'),
            $container = $('._filter-container');
        $container.removeClass('filtered').find('._filter-item').removeClass('hidden');
        if ('all' != selector) {
          $container.find(`._filter-item:not([data-filter-item="${selector}"])`).addClass('hidden');
          $container.addClass('filtered');
        }
      });
    }
  }
}

export default Filter;
